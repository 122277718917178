<template>
	<div data-component="edit-teacher-options-list-modal">
		<div data-element="options-list">
			<dynamic-table
				:columns="columns"
				:data="getOptions"
				:activeRow="activeItem"
				max-height="376px"
			>
				<template #option="{ row }">
					<div
						data-element="option-wrapper"
						v-html="row"
					/>
				</template>
				<template #action="{ row, index }">
					<div data-element="option-actions">
						<V2Button
							size="sm"
							@click="handleEdit(row, index)"
						>
							Edit
						</V2Button>
						<V2Button
							size="sm"
							variant="red"
							:btnId="getDeleteOptionBtnId(index)"
							:isDisabled="getDeleteOptionBtnDisabled(index)"
							@click="handleDelete(row, index)"
						>
							Delete
						</V2Button>
					</div>
				</template>
			</dynamic-table>
		</div>
		<div data-element="add-new-btn">
			<V2Button
				size="md"
				btnId="addNewBtn"
				variant="red"
				:isDisabled="isAddNewBtnDisabled"
				@click="handleAddNew"
			>
				Add new
			</V2Button>
		</div>
		<div
			v-if="getShowField"
			data-element="edit-option"
		>
			<validation-observer
				data-element="form"
				tag="form"
				ref="form"
				@submit.prevent="handleSave"
			>
				<wysiwyg-field
					:name="getFieldName"
					type="text"
					:hasEditOptions="extendedEditor"
					:hasListControl="extendedEditor"
					:hasLinkControl="extendedEditor"
					:value="getActiveOption"
					@input="handleOptionInput"
				/>
				<actions
					:actions="getActions"
					:isWaiting="isWaiting"
					@actionClick="onActionClick"
				/>
			</validation-observer>
		</div>
	</div>
</template>

<script>

	import DynamicTable from '@/components/ui/views/dynamicTable/DynamicTable';
	import V2Button           from '@/components/v2/ui/V2Button';
	import Actions from '@/components/ui/Actions';
	import actionClick from '@/mixins/actionClick';
	import invalidFormMessage from '@/mixins/invalidFormMessage';

	export default {
		components: {
			DynamicTable,
			V2Button,
			WysiwygField: () => import('@/components/forms/WysiwygField'),
			Actions
		},
		mixins: [actionClick, invalidFormMessage],
		props: {
			options: {
				type: Array,
				default: undefined
			},
			entityName: {
				type: String,
				default: 'Option'
			},
			isWaiting: {
				type: [String, undefined],
				default: undefined
			},
			extendedEditor: {
				type: Boolean,
				default: false
			}
		},
		data () {
			return {
				columns: [
					{
						label: this.entityName,
						key: 'option'
					},
					{
						label: '',
						key: 'action'
					}
				],
				activeItem: undefined,
				isAddingNew: false,
				newItem: ''
			};
		},
		computed: {
			getOptions () {
				return this.options || [];
			},
			getShowField () {
				return this.isAddingNew || this.getOptions[this.activeItem] !== undefined;
			},
			getActiveOption () {
				return this.isAddingNew ? this.newItem : this.getOptions[this.activeItem];
			},
			getSaveBtnId () {
				return `save${this.entityName.replaceAll(' ', '')}`;
			},
			getActions () {
				return {
					primary: [
						{
							text: 'Save',
							btnId: this.getSaveBtnId,
							type: 'submit'
						}
					],
					secondary: [
						{
							text: 'Cancel',
							actionId: 'handleEditCancel'
						}
					]
				};
			},
			getFieldName () {
				return this.isAddingNew ? `Add ${this.entityName}` : `Edit ${this.entityName}`;
			},
			isAddNewBtnDisabled () {
				return this.isAddingNew ? 'addNewBtn' : undefined;
			}
		},
		created () {},
		methods: {
			close () {
				this.$emit('close');
			},
			resetOptionField () {
				this.isAddingNew = false;
				this.$nextTick(() => {
					this.isAddingNew = true;
				});
			},
			handleAddNew () {
				this.activeItem = undefined;
				this.$nextTick(() => {
					this.isAddingNew = true;
				});
			},
			handleEdit (option, index) {
				this.activeItem = undefined;
				this.isAddingNew = false;
				this.$nextTick(() => {
					this.activeItem = index;
				});
			},
			handleEditCancel () {
				this.activeItem = undefined;
				if (this.isAddingNew) {
					this.isAddingNew = false;
					this.newItem = '';
				}
			},
			handleDelete (_, index) {
				if (confirm('Are you sure?')) {
					this.activeItem = undefined;
					this.$emit('onDelete', index);
				}
			},
			getDeleteOptionBtnId (index) {
				return `delete-${index}`;
			},
			getDeleteOptionBtnDisabled () {
				if (this.isWaiting !== undefined) {
					return this.getDeleteOptionBtnId(this.isWaiting);
				}
			},
			handleOptionInput (value) {
				if (this.isAddingNew) {
					this.newItem = value;
				} else if (this.activeItem !== undefined) {
					this.$set(this.options, this.activeItem, value);
				}
			},
			async handleSave () {
				const valid = await this.$refs.form.validate();
				if (!valid) {
					this.showInvalidFormMessage();

				}
				if (this.isAddingNew) {
					this.$emit('onAddNew', this.newItem);
					this.newItem = '';
					this.resetOptionField();
				} else {
					this.$emit('onSave', this.getSaveBtnId);
				}
			}
		}
	};
</script>

<style lang="scss" scoped>

	[data-component='edit-teacher-options-list-modal'] {
		padding-bottom: rem(10);
		[data-element='options-list'] {
			::v-deep [data-component='dynamic-table'] {
				[data-element='table-column-action'] {
					width: 200px;
					[data-element='option-actions'] {
						display: flex;
						justify-content: flex-end;
						.btn {
							margin-left: rem(10);
						}
					}
				}
				[data-element='table-column-option'] {
					width: calc(100% - 100px);

					[data-element='option-wrapper'] {
						@include rich-text-content;
						@include rich-text-suits;

						p {
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
					}
				}
			}
		}
		[data-element='add-new-btn'] {
			margin-top: rem(18);
			[data-component='btn'] {
				width: fit-content;
			}
		}
		[data-element='edit-option'] {
			[data-element='form'] {
				margin-bottom: 0;
				[data-component='wysiwyg-field'] {
					margin-bottom: 0;
				}
				[data-component='actions'] {
					padding-bottom: 0;
				}
			}
		}
	}

</style>
